import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../../layouts/page';
import Submit from '../../../components/button';
import { config } from '../../../config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Backbtn from '../../../components/backbtn';
import Editor from '../../../components/editor/index';
import ImageUploader from '../../../components/imageuploader';


const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '', 
    description: '',
    importance: '',
    image: null,
  });
  const [loading, setLoading] = useState(false);
  
  const fetchServiceProvider = async () => {
    const token = localStorage.getItem('auth_token');
    try {
      const response = await axios.get(`${config.api_path}/service-categories/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = response.data.data;
      setFormData({
        name: data.name || '', // Ensure name is set properly
        description: data.description || '',
        importance: data.importance || '',
        image: data.image || null
      });
    } catch (error) {
      console.error('Error fetching service provider details:', error);
      toast.error('Failed to load service provider details. Please try again later.');
    }
  };
 
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };
  const handleFileChange = (name, files) => {
    if (files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    }
  };

  useEffect(() => {
    document.title = 'Edit Service Provider';
    fetchServiceProvider();
  }, []);
  
  const handleDescriptionQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData();
  
    // Validate and append data to FormData object
    if (!formData.name.trim()) {
      toast.error('Name field is required.'); // Show error if name is empty
      setLoading(false);
      return;
    }
    
    formDataToSubmit.append('name', formData.name);

    if (!formData.description.trim()) {
      toast.error('Description field is required.'); // Show error if description is empty
      setLoading(false);
      return;
    }
    
    formDataToSubmit.append('description', formData.description);
    formDataToSubmit.append('importance', formData.importance);
    
    if (formData.image) {
      if((typeof formData.image) != 'string' ){
        formDataToSubmit.append('image', formData.image)
      }
    }
  
    try {
      await axios.post(`${config.api_path}/service-categories/${id}`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success('Service provider updated successfully!');
      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000); // Adjust the delay as necessary

    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || 'An error occurred during update.');
      console.error('Update error:', error);
    }
  };
  
  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Update Service Provider Category</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <section className='col-md-8'>
                  <div className='row'>
                      <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Name<span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                      </div>
                      <div className="form-group col-md-6">
                      <label className="col-form-label">Group<span>*</span></label>
                      <select
                        className="form-control"
                        name="importance"
                        value={formData.importance} 
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Group</option>
                        <option value="Essential">Emergency</option>
                        <option value="Relevant">Essential</option>
                        <option value="Desirable">Desirable</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                  <Editor
                    width="col-md-12"
                    label="Description"
                    value={formData.description}
                    onChange={handleEditorChange}
                  />
                  
                  </div>
                </section>
                <ImageUploader
                  title={`Category image`}
                  image={formData.image}
                  onChange={(e) => handleFileChange('image', e.target.files)}
                />
              </div>

              {/* Submit Button */}
              <Submit loading={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../../layouts/page';
import { config } from '../../config/index';
import Submit from '../../components/button';
import { useNavigate } from 'react-router-dom';
import Backbtn from '../../components/backbtn';
import ImageUploader from '../../components/imageuploader';

const Create = () => {
  const estateID = localStorage.getItem('estateID');
  const [formData, setFormData] = useState({
    name: '',
    estate_id: estateID,
    location: '',
    leader_name: '',
    leader_phone_number: '',
    image: null,
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Tuwe | Create Boda Stage";
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Adjust regex according to your requirements
    return phoneRegex.test(phone);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('auth_token');
    const formDataToSubmit = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      formDataToSubmit.append(key, value);
    });

    // Validate phone number before submitting
    if (!validatePhoneNumber(formData.leader_phone_number)) {
      setLoading(false);
      toast.error('Please enter a valid phone number (10 digits).');
      return;
    }

    try {
      await axios.post(`${config.api_path}/boda-stages`, formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      toast.success('Boda Stage created successfully!');
      
      // Reset form data after successful submission
      setFormData({
        name: '',
        estate_id: estateID,
        location: '',
        leader_name: '',
        leader_phone_number: '',
        image: null,
      });

      setTimeout(() => {
        navigate(-1); // Redirect to the specified route
      }, 2000);

    } catch (error) {
      toast.error(error.response?.data?.message || 'Error creating boda stage');
      console.error('Error creating boda stage:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4>Create Boda Stage</h4>
            <Backbtn />
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <section className='col-md-8'>
                  <div className='row'>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Name<span>*</span></label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter stage name"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Location<span>*</span></label>
                    <input
                      type="text"
                      name="location"
                      className="form-control"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder="Enter location"
                      required
                    />
                  </div>
                  </div>
                  <div className='row'>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Leader's Name<span>*</span></label>
                    <input
                      type="text"
                      name="leader_name"
                      className="form-control"
                      value={formData.leader_name}
                      onChange={handleInputChange}
                      placeholder="Enter leader's name"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">Leader's Mobile<span>*</span></label>
                    <input
                      type="tel"
                      name="leader_phone_number"
                      className="form-control"
                      value={formData.leader_phone_number}
                      onChange={handleInputChange}
                      placeholder="Enter leader's mobile"
                      required
                    />
                  </div>
                  </div>
                </section>
                <ImageUploader
                  title="Boda Stage Image"
                  image={formData.image}
                  onChange={(e) => setFormData((prev) => ({ ...prev, image: e.target.files[0] }))} // Change to handle the file directly
                  required
                />
              </div>
                <Submit loading={loading} disabled={loading} />
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
